import React, { useEffect } from 'react'
import { Tabs } from 'antd'
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom'

type RouteTab = {
  path: string
  label: string
}

type RouteAwareTabProps = {
  basePath: string
  tabs: RouteTab[]
}

const RouteAwareTabs: React.FC<RouteAwareTabProps> = ({ basePath, tabs }) => {
  const location = useLocation()
  const goto = useNavigate()
  const currentPath = location.pathname
  const activePath =
    tabs.find(tab => currentPath.includes(tab.path))?.path || tabs[0].path

  useEffect(() => {
    // redirect to the first tab if the current path is not in the tabs
    if (!tabs.some(tab => currentPath.includes(tab.path))) {
      goto(`${basePath}${tabs[0].path}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Tabs
      activeKey={activePath}
      animated={false}
      size="small"
      items={tabs.map(({ path, label }) => ({
        key: path,
        label: <Link to={`${basePath}${path}`}>{label}</Link>,
        children: <Outlet />,
        style: { height: '100%' },
      }))}
      style={{ height: '100%' }}
    />
  )
}

export default RouteAwareTabs
