import { useParams } from 'react-router-dom'
import {
  Button,
  Card,
  Col,
  DatePicker,
  Flex,
  Form,
  Input,
  Row,
  Select,
  Space,
  Typography,
} from 'antd'
import {
  useAddAssociationMember,
  useAssociationMembers,
} from '../../hooks/association/useAssociationMembers'
import { useBoolean } from '../../hooks/useBoolean'
import {
  useJoinMeeting,
  useMeeting,
  useUpdateMeeting,
} from '../../hooks/meeting/useMeetings'
import React, { useEffect, useMemo, useState } from 'react'
import {
  LocationType,
  Meeting,
  MeetingStatus,
  NewAssociationMemberInput,
} from '../../types/graphql-types'
import _ from 'lodash'
import { PageContent } from '../templates/page'
import {
  CloudUploadOutlined,
  DeleteOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons'
import Loading from '../atoms/loading'
import dayjs from 'dayjs'
import { humanize } from '../../utils/utils'
import XhrPending from '../atoms/xhr-pending'
import ParticipantSelectRender from '../organism/participant-select-render'
import CreateEditAssociationMemberModal from '../organism/association-member-modal'

type ParticipantOption = {
  label: string
  value: string
}

const MeetingDetailsOverview = () => {
  const { id } = useParams()
  const [form] = Form.useForm()
  const { members, refetch: refetchMembers } = useAssociationMembers()
  const [xhrPending, toggleXhrPending] = useBoolean()
  const [createMemberModalOpened, toggleCreateMemberModal] = useBoolean()
  const { loading, meeting, refetch } = useMeeting(id!!)
  const { updateMeeting, addParticipants, removeParticipants } =
    useUpdateMeeting()
  const [mutatingParticipants, toggleMutatingParticipants] = useBoolean()
  const [joining, toggleJoining] = useBoolean()
  const join = useJoinMeeting(id!!)
  const [participants, setParticipants] = useState<string[]>([])
  const [participantOptions, setParticipantOptions] = useState<
    ParticipantOption[]
  >([])
  const [formValues, setFormValues] = useState<Meeting>()
  const addMember = useAddAssociationMember()

  const readOnly = useMemo(
    () =>
      [MeetingStatus.Completed, MeetingStatus.Cancelled].includes(
        meeting?.status!,
      ),
    [meeting],
  )

  useEffect(() => {
    if (meeting) setFormValues(meeting)
  }, [meeting])

  useEffect(() => {
    if (members && meeting?.participants) {
      const currentParticipantOptions = meeting.participants.map(p => ({
        label: p.participant.name,
        value: p.participant.id,
      }))

      setParticipants(currentParticipantOptions.map(p => p.value))

      setParticipantOptions(
        _.unionBy(
          [
            ...currentParticipantOptions,
            ...members.map(member => ({
              label: member.name,
              value: member.id,
            })),
          ],
          'value',
        ),
      )
    }
  }, [members, meeting])

  return (
    <>
      <Flex
        gap={12}
        align="center"
        justify="flex-end"
        style={{ width: '100%' }}
      >
        {!readOnly && meeting?.status !== MeetingStatus.Cancelled && (
          <Button type="default">
            <DeleteOutlined />
            Cancel meeting
          </Button>
        )}
        <Button type="primary" disabled={readOnly} onClick={form.submit}>
          <CloudUploadOutlined /> Save
        </Button>
      </Flex>
      <PageContent>
        {loading || !meeting ? (
          <Loading />
        ) : (
          <Form
            form={form}
            name={`meeting-form-${id}`}
            aria-readonly={!readOnly}
            onValuesChange={(_, values) => setFormValues(values)}
            onFinish={values => {
              toggleXhrPending()
              updateMeeting(id!!, {
                name: values.name,
                agenda: values.agenda,
                location: values.location,
                locationType: values.locationType,
                startTime: values.startTime.toISOString(),
                endTime: values.endTime?.toISOString(),
              })
                .then(refetch)
                .finally(toggleXhrPending)
            }}
            initialValues={{
              ...meeting,
              startTime: dayjs(meeting.startTime),
              endTime: meeting.endTime ? dayjs(meeting.endTime) : undefined,
            }}
            autoComplete="off"
          >
            <XhrPending pending={xhrPending}>
              <Flex vertical gap={24}>
                <Row align="stretch" justify="space-between" gutter={24}>
                  <Col span={16}>
                    <Card>
                      <Form.Item<Meeting>
                        label="Name"
                        name="name"
                        labelAlign="left"
                        labelCol={{ span: 4 }}
                        rules={[
                          {
                            required: true,
                            message: 'Please give the meeting a name',
                          },
                        ]}
                      >
                        <Input readOnly={readOnly} />
                      </Form.Item>

                      <Form.Item<Meeting>
                        label="Agenda"
                        name="agenda"
                        labelAlign="left"
                        labelCol={{ span: 4 }}
                        rules={[
                          {
                            required: true,
                            message: 'Please provide an agenda',
                          },
                        ]}
                      >
                        <Input.TextArea
                          readOnly={readOnly}
                          autoSize={{ minRows: 6, maxRows: 10 }}
                        />
                      </Form.Item>
                      <Form.Item<Meeting>
                        label="Location Type"
                        name="locationType"
                        labelAlign="left"
                        labelCol={{ span: 4 }}
                        rules={[
                          {
                            required: true,
                            message: 'Please select a location type',
                          },
                        ]}
                      >
                        <Select
                          open={readOnly ? false : undefined}
                          options={Object.values(LocationType).map(value => ({
                            label: humanize(value),
                            value,
                          }))}
                        />
                      </Form.Item>
                      <Form.Item<Meeting>
                        label={
                          formValues?.locationType === LocationType.Virtual
                            ? 'Link'
                            : 'Location'
                        }
                        name="location"
                        labelAlign="left"
                        labelCol={{ span: 4 }}
                        rules={
                          formValues?.locationType === LocationType.Virtual
                            ? [
                                {
                                  type: 'url',
                                  message: 'Please provide a valid meeting URL',
                                },
                              ]
                            : []
                        }
                      >
                        <Input
                          readOnly={readOnly}
                          suffix={
                            formValues?.locationType ===
                            LocationType.Physical ? undefined : (
                              <Button
                                type="primary"
                                disabled={
                                  joining ||
                                  meeting.status === MeetingStatus.Completed
                                }
                                loading={joining}
                                onClick={() => {
                                  toggleJoining()
                                  join().finally(toggleJoining)
                                }}
                              >
                                <VideoCameraOutlined />
                                Invite Bot
                              </Button>
                            )
                          }
                        />
                      </Form.Item>
                    </Card>
                  </Col>
                  <Col span={8}>
                    <Space direction="vertical" style={{ width: '100%' }}>
                      <Card>
                        <Typography.Title level={5}>Status</Typography.Title>
                        <Typography.Text>
                          {humanize(meeting?.status)}
                        </Typography.Text>
                      </Card>
                      <Card>
                        <Form.Item<Meeting>
                          label="Start Time"
                          name="startTime"
                          labelAlign="left"
                          rules={[
                            {
                              required: true,
                              message: 'Please select a start time',
                            },
                          ]}
                        >
                          <DatePicker
                            showTime
                            readOnly={readOnly}
                            style={{ width: '100%' }}
                          />
                        </Form.Item>
                        <Form.Item<Meeting>
                          label="End Time"
                          name="endTime"
                          labelAlign="left"
                          style={{ marginBottom: 0 }}
                        >
                          <DatePicker
                            showTime
                            readOnly={readOnly}
                            style={{ width: '100%' }}
                          />
                        </Form.Item>
                      </Card>
                      <Card title="Participants">
                        <Select
                          style={{ width: '100%' }}
                          value={participants}
                          options={participantOptions}
                          loading={mutatingParticipants}
                          mode="tags"
                          disabled={readOnly}
                          dropdownRender={menu => (
                            <ParticipantSelectRender
                              menu={menu}
                              onClickAddParticipant={toggleCreateMemberModal}
                            />
                          )}
                          onSelect={value => {
                            toggleMutatingParticipants()
                            addParticipants(id!!, [value])
                              .then(() =>
                                setParticipants([...participants, value]),
                              )
                              .finally(toggleMutatingParticipants)
                          }}
                          onDeselect={value => {
                            toggleMutatingParticipants()
                            removeParticipants(id!!, [value])
                              .then(() =>
                                setParticipants(prev =>
                                  prev.filter(p => p !== value),
                                ),
                              )
                              .finally(toggleMutatingParticipants)
                          }}
                        />
                      </Card>
                    </Space>
                  </Col>
                </Row>
              </Flex>
            </XhrPending>
          </Form>
        )}
      </PageContent>
      {createMemberModalOpened && (
        <CreateEditAssociationMemberModal
          onCancel={toggleCreateMemberModal}
          onSave={newMemberInput => {
            const member = newMemberInput as NewAssociationMemberInput
            return addMember({
              name: member.name!!,
              email: member.email,
              role: member.role!!,
            })
              .then(refetchMembers)
              .then(toggleCreateMemberModal)
          }}
        />
      )}
    </>
  )
}

export default MeetingDetailsOverview
