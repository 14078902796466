import { gql } from '@apollo/client'
import useQuery from '../useQuery'
import { noop } from 'lodash'
import useMutation from '../useMutation'
import {
  Association,
  AssociationUpdateInput,
  NewAssociationInput,
} from '../../types/graphql-types'

const associationFragment = gql`
  fragment AssociationFragment on Association {
    id
    orgId
    name
    status
    type
    description
    compositionInstructions
    logo
    status
    address
    city
    zip
    state
    country
    locale
    currency
    phone
    email
    website
    createdAt
    updatedAt
  }
`

const associationsQuery = gql`
  ${associationFragment}
  query Associations {
    associations {
      ...AssociationFragment
    }
  }
`

export const useAssociations = () => {
  const { loading, data, refetch } = useQuery<{
    associations: Association[]
  }>(associationsQuery)

  return {
    loading,
    associations: data?.associations ?? [],
    refetch: () => refetch().then(noop),
  }
}

const createAssociationMutation = gql`
  mutation CreateAssociation($newAssociation: NewAssociationInput!) {
    createAssociation(newAssociation: $newAssociation) {
      id
    }
  }
`
export const useCreateAssociation = () => {
  const [createAssociation] = useMutation(createAssociationMutation)

  return (newAssociation: NewAssociationInput) =>
    createAssociation({ variables: { newAssociation } })
}

const updateAssociationMutation = gql`
  mutation UpdateAssociation($association: AssociationUpdateInput!) {
    updateAssociation(association: $association) {
      id
    }
  }
`

export const useUpdateAssociation = () => {
  const [updateAssociation] = useMutation(updateAssociationMutation)

  return (association: AssociationUpdateInput) =>
    updateAssociation({ variables: { association } })
}

const grantAssociationAccessMutation = gql`
  mutation GrantAssociationAccess($associationId: String!, $memberId: String!) {
    grantAssociationAccess(associationId: $associationId, memberId: $memberId)
  }
`
export const useGrantAssociationAccess = () => {
  const [grantAssociationAccess] = useMutation(grantAssociationAccessMutation)

  return (associationId: string, memberId: string) =>
    grantAssociationAccess({ variables: { associationId, memberId } })
}

const revokeAssociationAccessMutation = gql`
  mutation RevokeAssociationAccess($associationId: String!, $memberId: String!) {
    revokeAssociationAccess(associationId: $associationId, memberId: $memberId)
  }
`
export const useRevokeAssociationAccess = () => {
  const [revokeAssociationAccess] = useMutation(revokeAssociationAccessMutation)

  return (associationId: string, memberId: string) =>
    revokeAssociationAccess({ variables: { associationId, memberId } })
}

const organizationMemberAssociationsQuery = gql`
  ${associationFragment}
  query OrganizationMemberAssociations($orgMemberId: String!) {
    organizationMemberAssociations(orgMemberId: $orgMemberId) {
      ...AssociationFragment
    }
  }
`
export const useOrganizationMemberAssociations = (orgMemberId: string) => {
  const { loading, data, refetch } = useQuery<{
    organizationMemberAssociations: Association[]
  }>(organizationMemberAssociationsQuery, {
    variables: { orgMemberId },
  })

  return {
    loading,
    associations: data?.organizationMemberAssociations ?? [],
    refetch: () => refetch().then(noop),
  }
}
