import { AssociationContext } from '../../context/association-context'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import useFileUpload from '../../hooks/useFileUpload'
import { useDocumentTitle } from '../../hooks/useDocumentTitle'
import { useUpdateAssociation } from '../../hooks/association/useAssociation'
import {
  Col,
  Flex,
  Input,
  Row,
  Select,
  Space,
  Switch,
  Tag,
  Typography,
} from 'antd'
import styled from 'styled-components'
import Button from '../atoms/button'
import {
  TwoColumnsContainer,
  TwoColumnsLeftContainer,
  TwoColumnsRightContainer,
} from '../molecules/two-columns'
import Panel from '../organism/panel'
import ImageUpload from '../molecules/img-upload'
import { InputLabel, InputLabelContainer } from '../atoms/input'
import moment from 'moment-timezone'
import { extensionFrom, humanize } from '../../utils/utils'
import { Media } from '../../types/media'
import { useBoolean } from '../../hooks/useBoolean'
import XhrPending from '../atoms/xhr-pending'
import {
  DollarCircleOutlined,
  GlobalOutlined,
  MailOutlined,
  PhoneOutlined,
  TranslationOutlined,
} from '@ant-design/icons'
import { AssociationStatus, AssociationType } from '../../types/graphql-types'
import Countries from 'country-list-js'
import * as Locales from 'locale-codes'
import Currencies from 'currency-list'
import _ from 'lodash'
import { Currency } from '../../types/currency'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-bottom: 24px;
`

const AssociationOverview = () => {
  const { upload } = useFileUpload()
  const update = useUpdateAssociation()
  const { association, refetch } = useContext(AssociationContext)
  useDocumentTitle(`Settings - ${association?.name}`, {
    skip: !association,
  })
  const [xhrPending, toggleXhrPending] = useBoolean()
  const [name, setName] = useState(association?.name)
  const [type, setType] = useState(association?.type)
  const [description, setDescription] = useState(association?.description)
  const [compositionInstructions, setCompositionInstructions] = useState(
    association?.compositionInstructions,
  )
  const [logo, setLogo] = useState<string>()
  const [logoFile, setLogoFile] = useState<File>()
  const [status, setStatus] = useState(association?.status)

  const [address, setAddress] = useState(association?.address)
  const [city, setCity] = useState(association?.city)
  const [zip, setZip] = useState(association?.zip)
  const [state, setState] = useState(association?.state)
  const [country, setCountry] = useState(association?.country)
  const selectedCountry = useMemo(
    () => Countries.findByIso3(country),
    [country],
  )
  const [locale, setLocale] = useState(association?.locale)
  const [currency, setCurrency] = useState(association?.currency)

  const [phone, setPhone] = useState(association?.phone)
  const [email, setEmail] = useState(association?.email)
  const [website, setWebsite] = useState(association?.website)

  const canSave = association && !!name && !!type

  useEffect(() => {
    if (association) {
      setName(association.name)
      setType(association.type)
      setDescription(association.description)
      setCompositionInstructions(association.compositionInstructions)
      setLogo(association.logo ?? '')
      setStatus(association.status)
      setAddress(association.address)
      setCity(association.city)
      setZip(association.zip)
      setState(association.state)
      setCountry(association.country)
      setLocale(association.locale)
      setCurrency(association.currency)
      setPhone(association.phone)
      setEmail(association.email)
      setWebsite(association.website)
    }
  }, [association])

  const save = () => {
    if (!canSave) return
    const uploadNewLogoIfAny = () =>
      logoFile
        ? upload(
            logoFile,
            `organizations/${association?.orgId}/associations/${association.id}/logo-${moment.now()}.${extensionFrom(logoFile)}`,
          ).then((media: Media) => media.url)
        : Promise.resolve(association?.logo ?? undefined)
    toggleXhrPending()
    uploadNewLogoIfAny()
      .then(logo =>
        update({
          id: association!.id,
          type,
          name,
          description,
          compositionInstructions,
          logo,
          status,
          address,
          city,
          state,
          zip,
          country,
          locale,
          currency,
          phone,
          email,
          website,
        }),
      )
      .then(refetch)
  }

  useEffect(() => {
    if (!association) return

    setType(association.type)
    // setDescription(association.description)
    // setCompositionInstructions(association.compositionInstructions)
    // setLogo(association.logo ?? '')
    // setStatus(association.status)
    // setAddress(association.address)
    // setCity(association.city)
    // setZip(association.zip)
    // setState(association.state)
    // setCountry(association.country)
    // setLocale(association.locale)
    // setCurrency(association.currency)
    // setPhone(association.phone)
    // setEmail(association.email)
    // setWebsite(association.website)
  }, [association])

  return (
    <XhrPending pending={xhrPending}>
      <Container>
        <Row align="middle" justify="end" gutter={8}>
          <Col>
            <Button
              analyticsId="update_organization"
              label="save"
              onClick={save}
              disabled={!canSave}
            />
          </Col>
        </Row>
        <TwoColumnsContainer>
          <TwoColumnsLeftContainer>
            <Panel>
              <Row>
                <Col flex="auto">
                  <ImageUpload
                    url={logo || association?.logo || ''}
                    label="Logo"
                    name="association-logo"
                    onUrlChange={setLogo}
                    onFileChange={setLogoFile}
                  />
                </Col>
                <Col span={20}>
                  <Space
                    direction="vertical"
                    size="middle"
                    style={{ display: 'flex' }}
                  >
                    <Row align="middle" gutter={12}>
                      <Col span={12}>
                        <InputLabelContainer>
                          <InputLabel>Name</InputLabel>
                          <Input
                            value={name || association?.name}
                            onChange={e => setName(e.target.value)}
                            allowClear
                            required
                          />
                        </InputLabelContainer>
                      </Col>
                      <Col span={12}>
                        <InputLabelContainer>
                          <InputLabel>Type</InputLabel>
                          <Select
                            showSearch
                            style={{ width: '100%' }}
                            value={type ?? association?.type}
                            options={Object.values(AssociationType).map(
                              type => ({
                                label: humanize(type),
                                value: type,
                              }),
                            )}
                            onChange={setType}
                          />
                        </InputLabelContainer>
                      </Col>
                    </Row>
                    <InputLabelContainer>
                      <InputLabel>Country</InputLabel>
                      <Select
                        showSearch
                        value={country || association?.country}
                        options={Object.values(Countries.all)
                          .map((c: any) => ({
                            label: c.name,
                            value: c?.iso3,
                          }))
                          .sort((a, b) => a.label.localeCompare(b.label))}
                        onChange={setCountry}
                        allowClear
                      />
                    </InputLabelContainer>
                    <InputLabelContainer>
                      <InputLabel>Address</InputLabel>
                      <Input
                        value={address || association?.address || ''}
                        onChange={e => setAddress(e.target.value)}
                        allowClear
                      />
                    </InputLabelContainer>
                    <InputLabelContainer>
                      <InputLabel>City</InputLabel>
                      <Input
                        value={city || association?.city || ''}
                        onChange={e => setCity(e.target.value)}
                        allowClear
                      />
                    </InputLabelContainer>
                    <Row align="middle" gutter={12}>
                      {/*state*/}
                      <Col span={12}>
                        <InputLabelContainer>
                          <InputLabel>State/Province</InputLabel>
                          <Select
                            showSearch
                            value={state || association?.state || ''}
                            options={
                              selectedCountry?.provinces
                                ?.map((c: any) => c.name)
                                ?.map((c: string) => ({
                                  label: c,
                                  value: c,
                                })) ?? []
                            }
                            onChange={setState}
                            allowClear
                          />
                        </InputLabelContainer>
                      </Col>
                      {/*zip*/}
                      <Col span={12}>
                        <InputLabelContainer>
                          <InputLabel>Zip</InputLabel>
                          <Input
                            value={zip || association?.zip || ''}
                            onChange={e => setZip(e.target.value)}
                            allowClear
                          />
                        </InputLabelContainer>
                      </Col>
                    </Row>
                  </Space>
                </Col>
              </Row>
            </Panel>
          </TwoColumnsLeftContainer>
          <TwoColumnsRightContainer style={{ gap: 12 }}>
            <Panel>
              <Flex
                align="center"
                justify="space-between"
                style={{ width: '100%' }}
              >
                <Tag
                  key={status}
                  color={
                    !status
                      ? 'gray'
                      : status === AssociationStatus.Active
                        ? 'green'
                        : 'red'
                  }
                >
                  {humanize(status)}
                </Tag>
                <Switch
                  checked={status === AssociationStatus.Active}
                  onChange={checked => {
                    const promise = checked
                      ? update({
                          id: association!.id,
                          status: AssociationStatus.Active,
                        })
                      : update({
                          id: association!.id,
                          status: AssociationStatus.Inactive,
                        })
                    const confirmed = window.confirm(
                      `Are you sure you want to ${checked ? 'Activate' : 'disable'} ${association?.name}?`,
                    )
                    if (confirmed)
                      promise.then(() =>
                        setStatus(
                          checked
                            ? AssociationStatus.Active
                            : AssociationStatus.Inactive,
                        ),
                      )
                  }}
                />
              </Flex>
            </Panel>
            <Panel>
              <Input
                addonBefore={
                  <InputLabel>
                    <PhoneOutlined />
                  </InputLabel>
                }
                prefix={
                  selectedCountry?.dialing_code
                    ? selectedCountry.dialing_code
                    : ''
                }
                placeholder="Phone number"
                value={phone || association?.phone || ''}
                onChange={e => setPhone(e.target.value)}
                allowClear
              />
              <Input
                addonBefore={
                  <InputLabel>
                    <MailOutlined />
                  </InputLabel>
                }
                placeholder="Email"
                value={email || association?.email || ''}
                onChange={e => setEmail(e.target.value)}
                allowClear
              />
              <Input
                addonBefore={
                  <InputLabel>
                    <GlobalOutlined />
                  </InputLabel>
                }
                placeholder="Website"
                value={website || association?.website || ''}
                onChange={e => setWebsite(e.target.value)}
                allowClear
              />
            </Panel>
            <Panel>
              <Flex gap={24} align="center">
                <InputLabel>
                  <TranslationOutlined />
                </InputLabel>
                <Select
                  showSearch
                  style={{ width: '100%' }}
                  placeholder="Language"
                  value={locale}
                  options={_.uniqBy(Locales.all, 'tag').map(l => ({
                    label: l.name + (l.location ? ` (${l.location})` : ''),
                    value: l.tag,
                  }))}
                  onChange={setLocale}
                  allowClear
                />
              </Flex>
              <Flex gap={24} align="center">
                <InputLabel>
                  <DollarCircleOutlined />
                </InputLabel>
                <Select
                  showSearch
                  placeholder="Currency"
                  value={currency}
                  style={{ width: '100%' }}
                  options={_.uniqBy(
                    _.values(Currencies.getAll('en')) as Currency[],
                    'code',
                  )
                    .filter(c => !!c.code)
                    .map(c => ({
                      label: (
                        <Row key={c.code} align="middle" gutter={12}>
                          <Col>
                            <Typography.Text strong>{c.symbol}</Typography.Text>
                          </Col>
                          <Col>
                            <Typography.Text>{c.name}</Typography.Text>
                          </Col>
                        </Row>
                      ),
                      value: c.code,
                    }))}
                  onChange={setCurrency}
                  allowClear
                />
              </Flex>
            </Panel>
          </TwoColumnsRightContainer>
        </TwoColumnsContainer>
        <Panel
          title="Intelligence"
          subTitle="This information is used to provide more accurate transcription."
        >
          <Row align="middle" gutter={24}>
            <Col span={12}>
              <InputLabelContainer>
                <InputLabel>Business Summary</InputLabel>
                <Input.TextArea
                  value={description || association?.description || ''}
                  placeholder="Describe the association industry, operations and market..."
                  onChange={e => setDescription(e.target.value)}
                  autoSize={{ minRows: 6, maxRows: 9 }}
                />
              </InputLabelContainer>
            </Col>
            <Col span={12}>
              <InputLabelContainer>
                <InputLabel>Minutes Generation Instructions</InputLabel>
                <Input.TextArea
                  value={
                    compositionInstructions ||
                    association?.compositionInstructions ||
                    ''
                  }
                  placeholder="Describe how to generate minutes, the specific features and requirements..."
                  onChange={e => setCompositionInstructions(e.target.value)}
                  autoSize={{ minRows: 6, maxRows: 9 }}
                />
              </InputLabelContainer>
            </Col>
          </Row>
        </Panel>
      </Container>
    </XhrPending>
  )
}

export default AssociationOverview
