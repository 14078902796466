import {
  Col,
  DatePicker,
  Flex,
  Form,
  FormInstance,
  Input,
  Row,
  Select,
  Switch,
  Tooltip,
  Typography,
} from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import {
  AssociationMember,
  LocationType,
  Meeting,
  MeetingTranscriptFormat,
  NewDocumentInput,
  NewMeetingInput,
} from '../../types/graphql-types'
import { humanize } from '../../utils/utils'
import { useBoolean } from '../../hooks/useBoolean'
import FileUploader, {
  contentTypePerFileType,
  FileTypes,
} from './file-uploader'
import useFileUpload from '../../hooks/useFileUpload'
import { AssociationContext } from '../../context/association-context'
import { useCreateDocument } from '../../hooks/document/useDocuments'
import XhrPending from '../atoms/xhr-pending'
import { ExportOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { Link, useNavigate } from 'react-router-dom'
import ParticipantSelectRender from './participant-select-render'

type Props = {
  associationMembers: AssociationMember[]
  formRef?: React.ForwardedRef<FormInstance<NewMeetingInput>>
  onSubmit?: (values: NewMeetingInput) => Promise<Meeting>
  onAddParticipant?: () => void
}

// @ts-ignore
const lowerToHighPriorityFileTypes: Record<FileTypes, FileTypes> = {
  // Predefined priority map using FileTypes (lower -> higher priority)
  [FileTypes.MP3]: FileTypes.MP4,
}

const getFileType = (file: File): FileTypes | undefined => {
  return Object.keys(contentTypePerFileType).find(
    key => contentTypePerFileType[key as FileTypes] === file.type,
  ) as FileTypes | undefined
}

const onlyKeepPreferredFiles = (files: File[]): File[] =>
  files.filter((file, _, fileList) => {
    const fileType = getFileType(file)

    if (!fileType) return true // Keep the file if its type isn't recognized

    const higherPriorityFileType = lowerToHighPriorityFileTypes[fileType]

    // Check if there is a higher priority file in the list
    return (
      !higherPriorityFileType ||
      !fileList.some(f => getFileType(f) === higherPriorityFileType)
    )
  })

const NewMeetingForm: React.FC<Props> = ({
  formRef,
  associationMembers,
  onSubmit,
  onAddParticipant,
}) => {
  const goto = useNavigate()
  const { association } = useContext(AssociationContext)
  const [values, setValues] = React.useState<NewMeetingInput>()
  const [fromRecording, toggleFromRecording] = useBoolean(false)
  const [filesToUpload, setFilesToUpload] = useState<File[]>([])
  const [fileTypes, setFileTypes] = useState<FileTypes[]>([])
  const [uploading, toggleUploading] = useBoolean()
  const { upload } = useFileUpload()
  const createDocument = useCreateDocument()

  useEffect(() => {
    if (fromRecording) {
      setFileTypes([
        FileTypes.PDF,
        // FileTypes.DOCX, // TODO: Enable when supporting thumbnail preview for DOCX
        FileTypes.MP4,
        FileTypes.VTT,
      ])
    } else {
      setFileTypes([
        FileTypes.PDF,
        // FileTypes.DOCX // TODO: Enable when supporting thumbnail preview for DOCX
      ])
    }
    setFilesToUpload([])
  }, [fromRecording])

  useEffect(() => {
    if (fromRecording) {
      setFileTypes(
        Object.values(FileTypes)
          .filter(type => type !== FileTypes.DOCX) // TODO: Enable when supporting thumbnail preview for DOCX
          .filter(
            type =>
              !filesToUpload.some(
                file =>
                  contentTypePerFileType[type] === file.type ||
                  contentTypePerFileType[type] === file.type,
              ),
          ),
      )
    }
    //   eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filesToUpload])

  const submit = () => {
    if (!values) return

    if (fromRecording) {
      toggleUploading()
      let documentsToUpload: NewDocumentInput[] = []
      const valuesWithRecordingData = values as NewMeetingInput
      Promise.all(
        onlyKeepPreferredFiles(filesToUpload).map(async (file: File) => {
          const isDocument =
            contentTypePerFileType[FileTypes.PDF] === file.type ||
            contentTypePerFileType[FileTypes.DOCX] === file.type
          const filename = `organizations/${association?.orgId}/associations/${association?.id}/${isDocument ? 'documents' : 'recordings'}/${new Date().toISOString()}-${file.name}`

          if (isDocument)
            documentsToUpload.push({ name: file.name, storagePath: filename })

          const fileType = getFileType(file)
          return upload(file, filename).then(() => {
            if (FileTypes.MP4 === fileType || FileTypes.MP3 === fileType)
              valuesWithRecordingData.recordingPath = filename
            if (FileTypes.VTT === fileType) {
              valuesWithRecordingData.transcriptFormat =
                MeetingTranscriptFormat.Vtt
              valuesWithRecordingData.transcriptPath = filename
            }
          })
        }),
      )
        .then(() => onSubmit?.(valuesWithRecordingData))
        .then(meeting => {
          if (!meeting) throw new Error('Meeting not created')
          return documentsToUpload.length > 0
            ? Promise.all(
                documentsToUpload.map(document =>
                  createDocument([{ ...document, meetingId: meeting?.id }]),
                ),
              ).then(() => meeting)
            : meeting
        })
        .then(newMeeting => goto(`/meetings/${newMeeting.id}`))
        .then(() => setFilesToUpload([]))
        .finally(toggleUploading)
    } else
      onSubmit?.(values).then(newMeeting => goto(`/meetings/${newMeeting.id}`))
  }

  return (
    <Form
      ref={formRef}
      layout="vertical"
      name="new-meeting-form"
      onValuesChange={(_, values) => setValues(values)}
      initialValues={{ remember: true }}
      onFinish={submit}
      autoComplete="off"
    >
      <XhrPending pending={uploading}>
        <Form.Item<NewMeetingInput>
          label="Name"
          name="name"
          rules={[
            { required: true, message: 'Please give the meeting a name' },
          ]}
        >
          <Row gutter={24} justify="space-between" align="middle">
            <Col span={12}>
              <Input />
            </Col>
            <Col>
              <Flex gap={12} align="center">
                <Switch value={fromRecording} onChange={toggleFromRecording} />
                <Typography.Text>
                  From Recording{' '}
                  <Tooltip
                    title="Upload a video, audio, and/or transcript (VTT files only) from a past meeting"
                    placement="bottomRight"
                  >
                    <QuestionCircleOutlined />
                  </Tooltip>
                </Typography.Text>
              </Flex>
            </Col>
          </Row>
        </Form.Item>
        <Row gutter={12} align="middle">
          {/* start time */}
          <Col span={12}>
            <Form.Item<NewMeetingInput>
              label="Start Time"
              name="startTime"
              rules={[
                { required: true, message: 'Please select a start time' },
              ]}
            >
              <DatePicker showTime style={{ width: '100%' }} />
            </Form.Item>
          </Col>

          {/* end time */}
          <Col span={12}>
            <Form.Item<NewMeetingInput>
              label="End Time"
              name="endTime"
              rules={
                fromRecording
                  ? [{ required: true, message: 'Please select an end time' }]
                  : []
              }
            >
              <DatePicker showTime style={{ width: '100%' }} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={12} align="middle">
          <Col span={12}>
            <Form.Item<NewMeetingInput>
              label="Location Type"
              name="locationType"
              initialValue={LocationType.Virtual}
              rules={[
                { required: true, message: 'Please select a location type' },
              ]}
            >
              <Select
                defaultValue={LocationType.Virtual}
                options={Object.values(LocationType).map(value => ({
                  label: humanize(value),
                  value,
                }))}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item<NewMeetingInput>
              label={
                values?.locationType === LocationType.Virtual
                  ? 'Link'
                  : 'Address'
              }
              name="location"
              initialValue=""
              rules={
                values?.locationType === LocationType.Virtual && !fromRecording
                  ? [
                      {
                        type: 'url',
                        message: 'Please provide a valid meeting URL',
                      },
                    ]
                  : []
              }
            >
              <Input
                disabled={
                  fromRecording && values?.locationType === LocationType.Virtual
                }
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item<NewMeetingInput>
          label="Participants"
          name="participants"
          rules={[{ required: true, message: 'Please select participants' }]}
        >
          <Select
            options={associationMembers.map(member => ({
              label: member.name,
              value: member.id,
            }))}
            mode="tags"
            showSearch
            dropdownRender={menu => (
              <ParticipantSelectRender
                menu={menu}
                onClickAddParticipant={onAddParticipant}
              />
            )}
          />
        </Form.Item>

        <Form.Item<NewMeetingInput>
          label="Agenda"
          name="agenda"
          rules={[{ required: true, message: 'Please provide an agenda' }]}
        >
          <Input.TextArea autoSize={{ minRows: 3 }} />
        </Form.Item>

        <FileUploader
          supportedFileTypes={fileTypes}
          onChange={setFilesToUpload}
          message={
            fromRecording
              ? 'Click to upload one recording, one transcript and/or any supporting documents'
              : 'Click to upload any supporting documents'
          }
        />
        {fromRecording && (
          <Flex justify="flex-end" style={{ paddingTop: 5 }}>
            <Link
              to="https://en.wikipedia.org/wiki/WebVTT#Example_of_WebVTT_format"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn more about transcript <ExportOutlined />
            </Link>
          </Flex>
        )}
      </XhrPending>
    </Form>
  )
}

export default NewMeetingForm
