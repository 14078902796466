import React, { useEffect, useState } from 'react'
import {
  Col,
  Drawer,
  Flex,
  FormInstance,
  Input,
  Select,
  Table,
  TableProps,
  Tag,
  Typography,
} from 'antd'
import { DateTime } from 'luxon'
import { Page, PageContent, PageHeader } from '../templates/page'
import { SearchOutlined } from '@ant-design/icons'
import {
  AssociationMember,
  LocationType,
  Meeting,
  MeetingStatus,
  NewAssociationMemberInput,
  NewMeetingInput,
} from '../../types/graphql-types'
import { humanize } from '../../utils/utils'
import Spacer from '../atoms/spacer'
import useDebounce from '../../hooks/useDebounce'
import { useQueryParam } from '../../hooks/useQueryParam'
import Button from '../atoms/button'
import useInfiniteScroll from 'react-infinite-scroll-hook'
import Loading from '../atoms/loading'
import { useCreateMeeting, useMeetings } from '../../hooks/meeting/useMeetings'
import NewMeetingForm from '../organism/new-meeting-form'
import { useBoolean } from '../../hooks/useBoolean'
import {
  useAddAssociationMember,
  useAssociationMembers,
} from '../../hooks/association/useAssociationMembers'
import { Link } from 'react-router-dom'
import { t } from 'i18next'
import CreateEditAssociationMemberModal from '../organism/association-member-modal'

const columns: TableProps<Meeting>['columns'] = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (text, { id }) => <Link to={`/meetings/${id}`}>{text}</Link>,
  },
  {
    title: 'Status',
    key: 'status',
    dataIndex: 'status',
    render: (_, { status }) => <Tag key={status}>{humanize(status)}</Tag>,
  },
  {
    title: 'Link',
    key: 'location',
    dataIndex: 'location',
    render: (_, { location, locationType }) =>
      locationType === LocationType.Physical ? (
        '--'
      ) : (
        <Typography.Text copyable={!!location}>
          {location || '--'}
        </Typography.Text>
      ),
  },
  {
    title: 'Start time',
    key: 'startTime',
    dataIndex: 'startTime',
    render: date => DateTime.fromISO(date).toRelative(),
  },
  {
    title: 'End time',
    key: 'endTime',
    dataIndex: 'endTime',
    render: date => DateTime.fromISO(date).toRelative(),
  },
]

type NewMeetingDrawerProps = {
  xhrPending: boolean
  toggleCreateModal: () => void
  newMeetingFormRef: React.RefObject<FormInstance<NewMeetingInput>>
  members: AssociationMember[]
  onSubmit: (values: NewMeetingInput) => Promise<Meeting>
  onAddParticipant?: () => void
}

const NewMeetingDrawer: React.FC<NewMeetingDrawerProps> = ({
  xhrPending,
  toggleCreateModal,
  newMeetingFormRef,
  members,
  onSubmit,
  onAddParticipant,
}) => (
  <Drawer
    title="Create New Meeting"
    open
    loading={xhrPending}
    size="large"
    onClose={toggleCreateModal}
    styles={{
      body: {
        marginBottom: 24,
      },
    }}
    extra={
      <Button
        analyticsId={'submit_create_meeting'}
        label="Submit"
        type="primary"
        onClick={() => newMeetingFormRef.current?.submit()}
        loading={xhrPending}
      />
    }
  >
    <NewMeetingForm
      formRef={newMeetingFormRef}
      associationMembers={members}
      onSubmit={onSubmit}
      onAddParticipant={onAddParticipant}
    />
  </Drawer>
)

const MeetingsListPage = () => {
  const [query, setQuery] = useState('')
  const debouncedQuery = useDebounce<string>(query, 300)
  const [after, setAfter] = useState<number>()
  const [status, setStatus] = useQueryParam<MeetingStatus>('sta')
  const [items, setItems] = useState<Meeting[]>([])
  const { loading, page, refetch } = useMeetings({
    query: debouncedQuery,
    status,
    after,
  })
  const [createMemberModalOpened, toggleCreateMemberModal] = useBoolean()
  const [createModalOpened, toggleCreateModal] = useBoolean()
  const { members, refetch: refetchMembers } = useAssociationMembers()
  const newMeetingFormRef = React.useRef<FormInstance<NewMeetingInput>>(null)
  const [xhrPending, toggleXhrPending] = useBoolean()
  const createMeeting = useCreateMeeting()
  const addMember = useAddAssociationMember()

  const hasNextPage = (page?.next ?? 0) > 0
  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: () => setAfter(page?.next),
    disabled: !loading && !page,
    rootMargin: '0px 0px 400px 0px',
  })

  useEffect(() => {
    if (page) {
      const data = page?.data ?? []
      setItems(prev => (after ? [...prev, ...data] : data))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  useEffect(() => {
    setAfter(undefined)
  }, [debouncedQuery, status])

  useEffect(() => {
    if (createModalOpened) {
      newMeetingFormRef.current?.resetFields()
    }
    //   eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Page xhrPending={false}>
      <PageHeader>
        <Col>
          <Typography.Title level={3}>Meetings</Typography.Title>
        </Col>
        <Col>
          <Button
            analyticsId="create_meeting"
            label="+ Create"
            onClick={toggleCreateModal}
          />
        </Col>
      </PageHeader>
      <PageContent>
        <Flex style={{ width: '50%' }} gap={12} align="center">
          <Input
            prefix={<SearchOutlined />}
            value={query}
            onChange={event => setQuery(event.target.value)}
            allowClear
            style={{ width: '33%' }}
          />
          <Select
            placeholder="Status"
            value={status}
            allowClear
            style={{ width: '33%' }}
            onChange={val => setStatus(val as MeetingStatus)}
            options={Object.values(MeetingStatus).map(value => ({
              label: t(`meeting_status_${value}`),
              value,
            }))}
          />
        </Flex>

        <Spacer size="large" />
        <Table columns={columns} dataSource={items} rowKey="id" />
        {hasNextPage && <div ref={sentryRef} />}
        {loading && items.length > 0 && (
          <Spacer>
            <Loading />
          </Spacer>
        )}
      </PageContent>
      {createMemberModalOpened && (
        <CreateEditAssociationMemberModal
          onSave={newMemberInput => {
            const member = newMemberInput as NewAssociationMemberInput
            return addMember({
              name: member.name!!,
              email: member.email,
              role: member.role!!,
            })
              .then(refetchMembers)
              .then(toggleCreateMemberModal)
          }}
          onCancel={toggleCreateMemberModal}
        />
      )}

      {createModalOpened && (
        <NewMeetingDrawer
          xhrPending={xhrPending}
          toggleCreateModal={toggleCreateModal}
          newMeetingFormRef={newMeetingFormRef}
          members={members}
          onSubmit={async values => {
            toggleXhrPending()
            const newMeeting = await createMeeting(values)
            return refetch()
              .then(() => newMeetingFormRef.current?.resetFields())
              .then(toggleCreateModal)
              .then(() => newMeeting)
              .finally(toggleXhrPending)
          }}
          onAddParticipant={toggleCreateMemberModal}
        />
      )}
    </Page>
  )
}

export default MeetingsListPage
